import React from "react"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import Container from "../../components/UI/ContainerTwo"

const Politicas = () => {
  return (
    <Container>
      <Heading
        as="h5"
        content="SEGGASA Confidencialidad
Actualización Octubre, 2010"
      />
      <Text
        content="SEGGASA ha desarrollado relaciones con sus clientes, basada en el respeto y la honestidad. Somos conscientes de la fe y la confianza que demuestran cuando visite nuestra web y nos proporcione su información personal. Nosotros prometemos que el tratamiento de sus datos con honestidad.

Nosotros nunca compartimos su dirección de correo electrónico, numero de teléfono o información de tarjeta de crédito y no guarda relación con ningún partido, salvo las que sean necesarias para completar las transacciones que usted solicite."
      />

      <Heading as="h5" content="Información Personal:" />
      <Text
        content="Si usted hace una compra de recoger los siguientes tipos de información de usted:
Información de contacto-su nombre, dirección, teléfono, correo electrónico y otra información similar.

La información financiera, números de tarjetas de crédito, fecha de caducidad, y la facturación y las direcciones de envío que usted se conecta a su cuenta de los sistemas de SEGGASA.

"
      />

      <Heading as="h5" content="Cómo usamos la información:" />
      <Text content="Nuestro propósito en la recopilación de información personal es a proporcionar un seguro, simple y personalizada experiencia. Podemos usar su información personal para:" />

      <Text
        content={
          <ul>
            <li>
              Proporcionar los servicios y atención al cliente que solicita.
            </li>
            <li>
              Proceso de transacciones y enviar avisos sobre sus transacciones.
            </li>
            <li>Reunir tarifas y resolver problemas.</li>
            <li>Personalizar, y mejorar nuestros servicios.</li>
            <li>
              Enviar avisos que el servicio de actualización, y ofertas
              promocionales de acuerdo a sus preferencias
            </li>
          </ul>
        }
      />
    </Container>
  )
}

export default Politicas
